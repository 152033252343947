import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "digital-empowerment"
};
import DigitalEmpowermentBanner from "@/views/digital_empowerment_program/digital-empowerment-module/digital-empowerment-banner.vue";
import DigitalEmpowermentContent from "@/views/digital_empowerment_program/digital-empowerment-module/digital-empowerment-content.vue";
export default {
  __name: 'index',
  setup: function setup(__props) {
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(DigitalEmpowermentBanner), _createVNode(DigitalEmpowermentContent)]);
    };
  }
};