import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-4aaa74e2"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "digital-empowerment-describe"
};
var _hoisted_2 = {
  class: "digital-empowerment-title-text"
};
var _hoisted_3 = {
  class: "digital-empowerment-title-text"
};
var _hoisted_4 = {
  class: "digital-empowerment-title-text"
};
var _hoisted_5 = {
  class: "digital-empowerment-desc-text mt80"
};
var _hoisted_6 = {
  class: "digital-empowerment-desc-text"
};
import { ref, onMounted } from "vue";
export default {
  __name: 'digital-empowerment-banner',
  setup: function setup(__props) {
    var screenHeight = ref(0);
    screenHeight.value = (window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight) - 80;
    onMounted(function () {
      window.onresize = function () {
        return function () {
          screenHeight.value = (window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight) - 80;
        }();
      };
    });
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", {
        class: "digital-empowerment",
        style: _normalizeStyle({
          height: _unref(screenHeight) + 'px'
        })
      }, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, "UNI" + _toDisplayString(_ctx.$t('empowerment.digging')), 1), _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('empowerment.bigHealth')), 1), _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('empowerment.oceanMarket')), 1), _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('empowerment.metaverse')), 1), _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t('empowerment.futureShoes')), 1)])], 4);
    };
  }
};